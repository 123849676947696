import React, { useRef, useEffect } from 'react';

function PartnerScroll() {
  const partners = [
    {
      name: 'Linde Snabbflytt AB',
      logoUrl: 'https://www.lindesnabbflytt.se/wp-content/uploads/2024/02/cropped-linde-snabb.png',
    },
    {
      name: 'Cederholms Plåtslageri',
      logoUrl: 'https://static.hitta.se/static/products/images/Prio4/cederhomsplatslageriab.il.jpg'
    },
    {
      name: 'Gasolproffsen',
      logoUrl: 'https://usercontent.one/wp/www.mkmedia.nu/wp-content/uploads/2023/03/328951486_933136911014636_8257626830227150290_n.jpg?media=1669757770'
    },
    {
        name: 'Kopparbergsmacken AB',
        logoUrl: 'https://www.kopparberg.net/uploads/2AviCoYC/305x0_389x0/Macken-i-Kopparberg_logo_transparent.png'
    },
    {
        name: 'Juridium',
        logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQF4tajAIcMYiw/company-logo_200_200/0/1630528847794/juridium_ab_logo?e=1726704000&v=beta&t=geDEn8WQXD0mVJMuPIIfRnx2GZRaxKspZncBHoD5qeQ'
    },
    {
      name: 'Platina Nutrition',
      logoUrl: 'https://i0.wp.com/platinanutrition.se/wp-content/uploads/2024/01/Logga-header.png?fit=1024%2C512&ssl=1'
    },
    {
      name: 'Trycktrollet',
      logoUrl: 'https://trycktrollet.se/wp-content/uploads/2024/05/trycktrollet-3-1024x819.png'
    },
    {
      name: 'Jobbjätten i Sverige AB',
      logoUrl: 'https://media.licdn.com/dms/image/D560BAQHb9yRbFQ9G9w/company-logo_200_200/0/1717099105366/jobbjtten_i_sverige_ab_logo?e=2147483647&v=beta&t=vhkgf-RzbcDnj6JAHBVioFK_-o4aWhs1RDki3Xa8Jn4'
    },
    {
      name: 'Sågbladsfabriken Stjärnsågar AB',
      logoUrl: 'https://sågbladsfabriken.se/wp-content/uploads/2024/02/sagbladsfabriken_logo_web.png'
    },




  ];

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let scrollLeft = 0;
    const intervalId = setInterval(() => {
      scrollLeft += 1; // adjust the scrolling speed here
      if (scrollLeft >= container.scrollWidth - container.clientWidth) {
        scrollLeft = 0;
      }
      container.scrollLeft = scrollLeft;
    }, 15); // adjust the scrolling interval here

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full py-20 text-white px-4 bg-black" id="Referenser">
        <h1 className="text-5xl font-bold mb-[100px] content-center justify-center flex">Trusted by</h1>
    <div className="flex overflow-hidden" ref={containerRef}>
      {partners.map(partner => (
        <div key={partner.name} className="bg-white border-[4px] border-gray-600 shadow-md py-4 px-16 mx-2 flex-shrink-0 mr-[200px] ">
          <img src={partner.logoUrl} alt={partner.name} className="h-[150px] bg-cover" />
        </div>
      ))}
    </div>
    </div>
  );
}

export default PartnerScroll;