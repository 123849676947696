import React from 'react'
import eliash from '../assets/eliash.jpg'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useState, useEffect, useRef } from 'react';



 

const Navbar = () => {
  
  const [nav, setNav] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)){
      setNav(false)
      }
    };
  
    
    document.addEventListener("mousedown", handler);
    
  });

 



  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 w-full mx-auto px-4  text-white bg-zinc-900 sticky top-0 z-10" ref={menuRef}>
        <img src={ eliash } alt="Elias Håkansson AB logotyp" className="h-20 ml-2 border-solid border-2 border-white" />
         <ul className='hidden md:flex font-bold'>
          <li className="p-4"><a href="#Hem">Hem</a></li>
          <li className="p-4"><a href="#Tjänster">Tjänster</a></li>
          <li className="p-4"><a href="#Priser">Priser</a></li>
          <li className="p-4"><a href="#Referenser">Referenser</a></li>
          <li className="p-4"><a href="#Om">Om oss</a></li>
          <li className="p-4"><a href="#Kontakt" className="py-3 px-12 bg-red-600 hover:bg-red-700 ease-in-out duration-300">Kontakt</a></li>
         </ul>
         <div onClick={handleNav} className="block md:hidden">
         {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} /> }
         </div>

         <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
         <img src={ eliash } alt="elias håkansson ab logotyp" className="h-20 m-2 border-solid border-2 border-black" />
          <li className='p-4 border-b border-gray-600'><a href="#Hem" onClick={() => setNav(false)}>Hem</a></li>
          <li className='p-4 border-b border-gray-600'><a href="#Tjänster" onClick={() => setNav(false)}>Tjänster</a></li>
          <li className='p-4 border-b border-gray-600'><a href="#Priser" onClick={() => setNav(false)}>Priser</a></li>
          <li className='p-4 border-b border-gray-600'><a href="#Referenser" onClick={() => setNav(false)}>Referenser</a></li>
          <li className='p-4 border-b border-gray-600'><a href="#Om" onClick={() => setNav(false)}>Om oss</a></li>
          <li className='p-4 border-b border-gray-600'><a href="#Kontakt" onClick={() => setNav(false)}>Kontakt</a></li>

      </ul>
    </div>
  )

}

export default Navbar
