import React from 'react'
import eliashakansson from "../assets/eliashakansson.jpg";

const Företaget = () => {
  return (
    <div className="bg-[#DC2626] py-32 w-full px-4 z-10" id="Om">

      <div className="max-w-[1240px] mx-auto grid xl:grid-cols-2">
         <div className="flex flex-col justify-center"> 
           <p className="text-[#59ec59] font-bold"> Företaget</p>
           <h1 className="text-white md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Elias Håkansson AB
           </h1>
           <p className="text-white md:text-xl text-xs max-w-[750px] flex-1 flex-shrink">
           Vår verksamhet specialiserar sig inom webbutveckling, content creation och marknadsföring. Vi brinner för att skapa och förbättra digitala upplevelser för våra kunder. <br></br>

       När det gäller webbutveckling, är vi stolta över att erbjuda skräddarsydda lösningar för våra kunder som är både användarvänliga och visuellt tilltalande. Vi använder de senaste teknologierna och verktygen för att skapa webbplatser som är säkra, snabba och optimerade för sökmotorer. <br></br>

      Vi är också kunniga inom content creation och marknadsföring och hjälper mer än gärna till att skapa kreativt innehåll i form av text och grafik. Samtidigt kan vi också hjälpa till att driva in kunder till er verksamhet genom digital marknadsföring.


           </p>
           <a href="#Kontakt"><button className="bg-black text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-4 hover:bg-gray-900 ease-in-out duration-300">Kontakta oss</button></a>
         </div>
         <div className="w-[500px] mx-auto my-4 max-sm:w-[350px]">
        <img  src={eliashakansson} alt="Elias Håkansson" className='md:w-[500px] mx-auto my-4 max-md:w-[350px]'/>
        </div>
      </div>

    </div>
  )
}

export default Företaget