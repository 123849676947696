import React from 'react'
import Editing2 from '../assets/editing2.jpg';
import Marketing from '../assets/marketing.jpg';
import Programming from '../assets/programming.jpg';


const Cards = () => {
  return (
    <div className="w-full py-[10rem] px-4 bg-[#DC2626] z-10" id="Priser">

      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 bg-zinc-900">
         <img className="w-[250px] mx-auto mt-[-3rem] bg-black hover:scale-105 duration-300" src={Programming} alt="Webbutveckling"/>
         <h2 className="text-2xl font-bold text-center py-8 text-white">Webbutveckling</h2>
         <p className="text-center text-4xl font-bold text-[#59ec59] drop-shadow-2xl underline underline-offset-2 decoration-4">från 5000kr</p>
         <div className="text-center font-medium text-white">
          <p className="py-2 border-b-2 border-white mx-8 mt-8">Hemsida</p>
          <p className="py-2 border-b-2 border-white mx-8">SEO</p>
          <p className="py-2 font-bold text-green-100 mx-8 mt-8">Kontakta oss för offert!</p>
         </div>
         <button className="bg-[#DC2626] text-white w-[200px] rounded-md font-bold my-6 mx-auto px-6 py-6 hover:bg-red-700 ease-in-out duration-300"><a href="#Kontakt">Kontakta oss!</a></button>
        </div>
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 bg-zinc-900">
          <img className="w-[250px] mx-auto mt-[-3rem] bg-black hover:scale-105 duration-300" src={Editing2} alt="Videoredigering"/>
          <h2 className="text-2xl font-bold text-center py-8 text-white">Videoskapande</h2>
          <p className="text-center text-4xl font-bold text-[#59ec59] drop-shadow-2xl underline underline-offset-2 decoration-4"> från 399kr/h</p>
          <div className="text-center font-medium text-white">
            <p className="py-2 border-b-2 border-white mx-8 mt-8">Short-Form content</p>
            <p className="py-2 border-b-2 border-white mx-8">Klippning</p>
            <p className="py-2 font-bold mx-8 mt-8">Kontakta oss för offert!</p>
          </div>
          <button className="bg-[#DC2626] text-white w-[200px] rounded-md font-bold my-6 mx-auto px-6 py-6 hover:bg-red-700 ease-in-out duration-300"><a href="#Kontakt">Kontakta oss!</a></button>
        </div>
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 bg-zinc-900">
          <img className="w-[250px] mx-auto mt-[-3rem] bg-black hover:scale-105 duration-300" src={Marketing} alt="Marknadsföring"></img>
          <h2 className="text-2xl font-bold text-center py-8 text-white">Marknadsföring</h2>
          <p className="text-center text-4xl font-bold text-[#59ec59] drop-shadow-2xl underline underline-offset-2 decoration-4">Priset varierar</p>
          <div className="text-center font-medium text-white">
            <p className="py-2 border-b-2 border-white mx-8 mt-8">Meta, google och Tiktok ads</p>
            <p className="py-2 border-b-2 border-white mx-8 ">Kundanalys</p>
            <p className="py-2 font-bold mx-8 mt-8">Kontakta oss för offert!</p>
          </div>

          <button className="bg-[#DC2626] text-white w-[200px] rounded-md font-bold my-6 mx-auto px-6 py-6 hover:bg-red-700 ease-in-out duration-300"><a href="#Kontakt">Kontakta oss!</a></button>

        </div>
      </div>

    </div>
  )
}

export default Cards