import React from 'react'

const Kontakt = () => {
  return (
    <div className="w-full py-16 text-white px-4 bg-black z-10">

        <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
          <div className="lg:col-span-2 my-4">
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold italic py-2 mb-6">
                Vill du komma i <span className="text-[#59ec59]">kontakt</span> med oss?
            </h1>
            <p className='font-bold text-gray-400'>Är du i behov av våra tjänster? Skriv dina kontaktuppgifter så hör vi av oss!</p>
          </div>
          <div className="my-4">

            <div className="flex flex-col sm:flex-row items-center justify-between w-full">
    
              <button type="submit" className="bg-[#DC2626] text-white rounded-md font-medium w-[300px] ml-4 my-6 px-8 py-6 hover:bg-red-700 ease-in-out duration-300">
               <a href="#Kontakt">Kontakta oss</a>
              </button>
            </div>

            <p>
              Vi skyddar din data. Läs våran <a className="text-[#DC2626]" target="_blank" rel="noreferrer" href="https://www.freeprivacypolicy.com/live/00ddfbc4-d6dd-4357-8472-85a2a94f50d6">Privacy policy</a>
            </p>

          </div>
        </div>
        
    </div>
  )
}

export default Kontakt