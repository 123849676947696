import React from 'react';
import {
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="text-gray-300 bg-zinc-900">
    <div className="w-[100%] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8">

        <div>
            <h1 className="w-full text-xl font-bold text-white">Elias Håkansson AB</h1>
            <p className="py-4">Tjänster inom webbutveckling, content creation och marknadsföring.</p>
            <div className="flex justify-between md:w-[75%] my-6">
                <a href="https://www.facebook.com/eliashakanssonab" target={'_blank'} rel="noreferrer"><FaFacebookSquare size={30} /></a>
                <a href="https://www.instagram.com/eliashakanssonab/" target={'_blank'} rel="noreferrer"><FaInstagram size={30} /> </a>
                <a href="https://twitter.com/hakanssonab" target={'_blank'} rel="noreferrer"><FaTwitterSquare size={30} /></a>
                <a href="https://github.com/Eliashakanssonn" target={'_blank'} rel="noreferrer"><FaGithubSquare size={30} /></a>
            </div>
        </div>
        <div className="lg:col-span-2 flex justify-between mt-6 md:px-4">
          <div>
            <h6 className="font-medium text-gray-400">Tjänster</h6>
            <ul>
              <li className="py-2 text-xs md:text-sm"><a href="#Priser">Webbutveckling</a></li>
              <li className="py-2 text-xs md:text-sm"><a href="#Priser">Content Creation</a></li>
              <li className="py-2 text-xs md:text-sm"><a href="#Priser">Marknadsföring</a></li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">Hjälp</h6>
            <ul>
              <li className="py-2 text-xs md:text-sm"><a href="#Kontakt">Kontakt</a></li>
              <li className="py-2 text-xs md:text-sm"><a href="#Priser">Priser</a></li>
            </ul>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Företaget</h6>
            <ul>
              <li className="py-2 text-xs md:text-sm"><a href="#Om">Om oss</a></li>
              <li className="py-2 text-xs md:text-sm"><a href="#Tjänster">Tjänster</a></li>
              <li className="py-2 text-xs md:text-sm"><a href="#Priser">Priser</a></li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">Rättsligt</h6>
            <ul>
              <li className="py-2 font-bold text-xs md:text-sm"><a className="text-[#DC2626]" target="_blank" rel="noreferrer" href="https://www.freeprivacypolicy.com/live/00ddfbc4-d6dd-4357-8472-85a2a94f50d6">Policy</a></li>
              <li className="py-2 font-bold text-xs md:text-sm"><a className="text-[#DC2626]" target="_blank" rel="noreferrer" href="https://www.freeprivacypolicy.com/live/9a9237cc-5d25-47bd-b205-908b71fb0869">Terms</a></li>
            </ul>
          </div>


        </div>

        

    </div>
    <div className="flex">
    <div className="block lg:grid pb-8 lg:grid-cols-2 mx-auto justify-center content-center">
      <p className="text-xs text-center p-4 lg:pr-[150px]"> © Copyright 2023 - Elias Håkansson AB | Alla rättigheter förbehålls</p>
      <p className="text-xs text-center p-4 lg:pl-[150px]">Hemsidan producerad av <a className="font-extrabold hover:text-gray-400 cursor-pointer" href="https://eliashakansson.se">Elias Håkansson AB</a></p>
    </div>
    </div>


    </div>
    
    
    
  )
}

export default Footer