import React from 'react';
import Cards from './components/Cards';
import FAQ from './components/FAQ.js';

import Footer from './components/Footer';
import Företaget from './components/Företaget';
import Hero from './components/Hero';
import Kontakt from './components/Kontakt';
import Navbar from './components/Navbar';
import PartnerScroll from './components/PartnerScroll';
import Testimonials from './components/Testimonials';
import Tjänster from './components/Tjänster';
import Kontaktform from './components/Kontaktform';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Tjänster />
      <Kontakt />
      <Cards />
      <PartnerScroll />
      <Företaget />
      <Kontaktform/>
      <Testimonials />
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
