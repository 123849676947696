import React from 'react'
import Editing from '../assets/editing.jpg'

const Tjänster = () => {
  return (
    <div className="w-full bg-[#DC2626] py-16 px-4 z-10" id='Tjänster'>
      <div className="max-w-[1240px] mx-auto grid xl:grid-cols-2">
        <div className="w-[500px] mx-auto my-4 max-sm:w-[350px]">
        <img  src={Editing} alt="Editing - Hemsida - Content Creation"/>
        </div>
        <div className="flex flex-col justify-center">
          <p className='text-[#59ec59] font-bold'>
           Tjänster
          </p>
          <h1 className='text-white md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
            Hur kan vi hjälpa dig?
          </h1>
          <p className="text-white text-xl max-w-[750px] flex-1 flex-shrink"> 
           Genom vår expertis inom flertalet tjänster så som webbutvecklandet av hemsidor, skapandet av engagerande 
           videos för sociala medier eller våra marknadsföringsstrategier kan vi <span className='text-[#59ec59] font-bold'>PUSHA</span> dig i rätt riktning för att nå eran fulla potential för relevans på internet.
          </p>
          <button className="bg-black text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-4 hover:bg-gray-900 ease-in-out duration-300"><a href="#Kontakt">Kontakta oss</a></button>
        </div>
      </div>
    </div>
  )
}

export default Tjänster