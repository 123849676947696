import React from 'react';
import emailjs from 'emailjs-com';

function sendEmail(e) {
  e.preventDefault();

  emailjs.sendForm('service_fbdo0w6', 'template_rwqmorq', e.target, 'bALiV2yC5lS0VJCHh')
    .then((result) => {
      console.log(result.text);
      alert('Your message was sent successfully!');
    }, (error) => {
      console.log(error.text);
      alert('There was an error sending your message. Please try again later.');
    });
}

function Kontaktform() {
  return (
    <div className="flex justify-center content-center" id="Kontakt">
     

      {/* Form column */}
      <div className="w-[75%] flex flex-col justify-center content-center px-6 py-12">
        <div className='text-center'>
          <h1 className="text-white font-extrabold text-xl bg-[#DC2626] p-6 rounded-xl md:mt-6 md:text-3xl mb-6 md:mb-12 md:text-[40px] min-[1150px]:mb-32">Diskutera din vision med oss!</h1>
        </div>

        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={sendEmail}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Kontaktperson
            </label>
            <input
              className="shadow appearance-none border rounded w-full text-[9px] md:text-xl py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              Name="Name"
              type="text"
              placeholder="Namn"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              E-post
            </label>
            <input
              className="shadow appearance-none border rounded text-[9px] md:text-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              Name="Email"
              placeholder="Kontaktpersonens e-postadress"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Företag/Organisation
            </label>
            <input
              className="shadow appearance-none border rounded w-full text-[9px] md:text-xl py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="Företag"
              type="Företag"
              Name="Company"
              placeholder="Företag/Organisation"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Meddelande
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full text-[9px] md:text-xl py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              rows="6"
              Name="Message"
              placeholder="Ditt meddelande"
            ></textarea>
          </div>
          <div className="flex items-center justify-center md:justify-end">
            <button
              className="bg-[#DC2626] hover:bg-[#aa1e1e] text-white font-bold py-3 px-16 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            
            >
              Skicka
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Kontaktform;