import React, { useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";

const FAQ = () => {
  const [active, setActive] = useState(null);

  const toggleFAQ = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const faqs = [
    {
      question: "Vilka ligger bakom Elias Håkansson AB?",
      answer:
        "Elias Håkansson AB är för tillfället ett enmanna bolag som drivs av Elias Håkannson. Elias stora intresse är allt som har med tech, AI, content creation och marknadsföring att göra. Elias startade detta bolag för att få hålla på med det han älskar samtidigt som han kan hjälpa andra.",
    },
    {
      question: "Hur kommer man i kontakt med oss?",
      answer:
        "För att komma i kontakt med oss är det lättast att skriva ett mail till kontakt@eliashakansson.se eller att fylla i formuläret på kontakt sidan på denna hemsida så hör vi av oss inom 24h. Vi är mer än gärna öppna för ett samtal över en kopp kaffe. ",
    },
    {
      question: "Hur snabbt kan jag få en offert?",
      answer:
        "Vi försöker alltid återkomma så fort som möjligt. Beroende på hur omfattande projektet är så kan det variera rent tidsmässigt. Målet är dock att du ska kunna få ta del av en offert inom 48 timmar.",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 my-12">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-6xl justify-center flex">
          FAQ
        </h2>
        <div className="mt-6 border-t border-gray-200 pt-6">
          {faqs.map((faq, index) => (
            <div key={index} className="mt-6">
              <div className="text-lg">
                <button
                  onClick={() => toggleFAQ(index)}
                  className="flex items-start justify-between w-full text-left focus:outline-none"
                >
                  <span className="font-medium text-white">
                    {faq.question}
                  </span>
                  {active === index ? (
                    <ChevronUpIcon className="h-6 w-6 text-white" />
                  ) : (
                    <ChevronDownIcon className="h-6 w-6 text-white" />
                  )}
                </button>
              </div>
              <div className={`mt-2 pr-12 transition duration-300 ease-in-out ${active === index ? 'max-h-full' : 'max-h-0 overflow-hidden'}`}>
                <p className="text-base text-gray-500">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;