const Testimonials = () => {
  const testimonialsData = [
    {
      name: "John Doe",
      jobTitle: "CEO of Company A",
      image: "https://via.placeholder.com/50",
      quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at semper magna. Nullam sed blandit nisl.",
      size: "text-lg",
    },
    {
      name: "Jane Doe",
      jobTitle: "Marketing Director at Company B",
      image: "https://via.placeholder.com/50",
      quote: "Sed pulvinar nisl risus, ac pellentesque sapien efficitur quis. Praesent auctor, neque quis ultrices dapibus, libero nunc tempus nibh, at vestibulum arcu velit vel ipsum.",
      size: "text-base",
    },
    {
      name: "Bob Smith",
      jobTitle: "CTO of Company C",
      image: "https://via.placeholder.com/50",
      quote: "Vivamus bibendum aliquet mi, in sodales purus faucibus nec. Duis sed suscipit elit, eu posuere dolor.",
      size: "text-sm",
    },
  ];

  return (
    <div className="bg-red-600 py-10 sm:py-12 md:py-16 lg:py-20 hidden">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-white text-center mb-8">Referenser</h2>
        <div className="flex flex-wrap -mx-4">
          {testimonialsData.map((testimonial, index) => (
            <div key={index} className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="bg-white h-full p-6 rounded-lg shadow-lg hover:bg-gray-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                <div className="flex items-center mb-4">
                  <img className="h-10 w-10 rounded-full mr-4" src={testimonial.image} alt={`Logo ${index + 1}`} />
                  <div>
                    <div className="text-base font-medium text-gray-900">{testimonial.name}</div>
                    <div className="text-base font-medium text-gray-500">{testimonial.jobTitle}</div>
                  </div>
                </div>
                <div className={`mb-4 ${testimonial.size}`}>
                  {testimonial.quote}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Testimonials