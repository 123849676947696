import React from 'react'
import bgImage from '../assets/solceller.jpg';
import bgImage2 from '../assets/solceller.jpg';


const Hero = () => {
  return (

   
    <div className="text-white z-10" id='Hem'>

<img src={bgImage} className="absolute -z-10 top-0 left-0 opacity-25 object-cover bg-center w-full h-screen hidden md:block" alt="Artificiell Intelligens"/>
<img src={bgImage2} className="absolute -z-10 top-0 left-0 opacity-25 object-cover bg-center w-full h-screen block md:hidden" alt="Videoredigering"/>

      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center"> 

      

        <p className="text-[#DC2626] font-bold p-2">
          WEBBUTVECKLING OCH MARKNADSFÖRING
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
         Välj tillväxt
        </h1>
        <div className='flex justify-center items-center' >
          <p className="md:text-3xl sm:text-3xl text-l font-bold py-4">
         vi hjälper dig bli relevant på
          </p>
          <p
          className='md:text-4xl sm:text-3xl text-xl font-bold md:pl-4 pl-2 text-[#DC2626]'>internet</p>
           
        
          
        </div>
        <p className="md:text-lg text-base font-bold text-gray-300 mx-4">
          Ta hjälp av oss för att öka din relevans på internet samtidigt som vi skapar tillväxt för ditt företag.
        </p>
        <button className='bg-[#DC2626] w-[200px] rounded-md font-bold my-6 mx-auto py-3 text-white hover:bg-red-700 ease-in-out duration-300'>
        <a href="#Kontakt">
          Kontakta oss
        </a>
         
        </button>
      </div>
    </div>
  )
}

export default Hero